import { yupResolver } from "@hookform/resolvers/yup";
import { CommonButton } from "components/button";
import PromoCodeCard from "components/cards/PromoCodeCard";
import FeatureDetailsHeader from "components/Header/FeatureDetailsHeader";
import { InputWithLabel } from "components/input";
import RadioInput from "components/input/RadioInput";
import ConfirmDelete from "components/modal/ConfirmDelete";
import { compareAsc, format } from "date-fns";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useCreatePromoCodeMutation,
  useDeletePromoCodeMutation,
  useGetAllPromoCodesQuery,
  useGetUserByEmailQuery,
  useUpdatePromoCodeMutation,
} from "services/staffService";
import { useActions, usePromoCodeSchema } from "./actions";
import {
  deleteButtonStyle,
  Divider,
  FormButton,
  FormSection,
  PromoDetailsBody,
  PromoDetailsContainer,
  PromoDetailsHeader,
  PromoDetailsLeft,
  PromoDetailsRight,
  PromoForm,
  PromoCodeIcons,
  PromoUsersBody,
  PromoUsersHeader,
  PromoCodeUsers,
  PromoUsersSubHeader,
  EmptyPromoUsers,
  EachPromoUser,
} from "./styled";
import { MdAutorenew } from "react-icons/md";
import { AiOutlineCopy } from "react-icons/ai";
import EmptyContent from "components/Fallbacks/EmptyContent";
import TagInput from "components/input/TagInput";

const PromoDetails = () => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedPromo, setSelectedPromo] = useState({});
  const [entityCodes, setEntityCodes] = useState([]);

  const navigate = useNavigate();
  const { promoCode } = useParams();

  const userInfo = useGetUserByEmailQuery(selectedPromo?.email, { skip: !selectedPromo?.email });
  const { data, refetch } = useGetAllPromoCodesQuery();
  const [createPromoCode, createState] = useCreatePromoCodeMutation();
  const [updatePromoCode, updateState] = useUpdatePromoCodeMutation();
  const [deletePromoCode, deleteState] = useDeletePromoCodeMutation();

  const { getPromoSchema } = usePromoCodeSchema({ data });

  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(getPromoSchema()),
    defaultValues: {
      minAmount: 0,
    },
  });

  const {
    generatePromo,
    copyPromoCode,
    submitForm,
    handleDeletePromo,
    handleActiveToggle,
    handlePromoEdit,
  } = useActions({
    data,
    promoCode,
    createPromoCode,
    updatePromoCode,
    deletePromoCode,
    refetch,
    setValue,
    reset,
    selectedPromo,
    setDeleteConfirm,
  });

  // Populate promo information
  useEffect(() => {
    if (!promoCode || !data) {
      reset();
      generatePromo();
      return;
    }

    const promoInfo = data?.find((el) => el?.promoCode === promoCode);
    setSelectedPromo(promoInfo);

    if (!promoInfo) {
      reset();
      return;
    }

    setValue("promoCode", promoInfo?.promoCode);
    setValue("promoCurrency", promoInfo?.promoCurrency);
    setValue("promoDescription", promoInfo?.promoDescription);
    setValue("promoDiscount", promoInfo?.promoDiscount);
    setValue("promoExpiry", format(new Date(promoInfo?.promoExpiry), "yyyy-MM-dd"));
    setValue("promoMaxAmount", promoInfo?.promoMaxAmount);
    setValue("minAmount", promoInfo?.minAmount);
    setValue("promoStatus", promoInfo?.promoStatus ? "Active" : "InActive");
    setValue("email", promoInfo?.email);
    setValue("entityCode", promoInfo?.entityCodes);
    setEntityCodes(promoInfo?.entityCode || []);
  }, [data, promoCode]);

  useEffect(() => {
    if (userInfo.isUninitialized) refetch();
  }, [selectedPromo]);

  const currencyOptions = ["NGN", "USD"];
  const statusOptions = ["Active", "InActive"];

  const promoUsers = userInfo?.data?.data?.promoUsers;

  return (
    <PromoDetailsContainer>
      <PromoDetailsHeader>
        <FeatureDetailsHeader
          backText="Back to promo codes"
          backURL="/staff-dashboard/promo-codes"
          title="Promo Codes"
          description="Manage all created promo codess"
          buttonText="Create New"
          buttonAction={() => navigate("/staff-dashboard/promo-codes/create")}
        />
      </PromoDetailsHeader>
      <PromoDetailsBody>
        <PromoDetailsLeft>
          <PromoForm onSubmit={handleSubmit(submitForm)}>
            <InputWithLabel
              inputId="promo-code"
              label="Promo Code"
              labelStyle="input-label"
              type="text"
              name="promoCode"
              inputClass="input-class"
              containerStyle="input-container-class"
              disable={promoCode}
              register={register}
              errorMessage={errors.promoCode?.message}
              overlayComponent={
                <PromoCodeIcons>
                  {!promoCode && (
                    <MdAutorenew size={20} style={{ cursor: "pointer" }} onClick={generatePromo} />
                  )}
                  <AiOutlineCopy
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={copyPromoCode}
                    className="button__effect"
                  />
                </PromoCodeIcons>
              }
            />
            <InputWithLabel
              label="Description"
              labelStyle="input-label"
              type="text"
              name="promoDescription"
              inputClass="input-class"
              containerStyle="input-container-class"
              register={register}
              errorMessage={errors.promoDescription?.message}
            />
            <FormSection>
              <InputWithLabel
                label="Discount"
                labelStyle="input-label"
                type="text"
                name="promoDiscount"
                inputClass="input-class"
                containerStyle="input-container-class"
                register={register}
                errorMessage={errors.promoDiscount?.message}
              />
              <Divider />
              <RadioInput
                label="Currency"
                labelStyle="input-label"
                name="promoCurrency"
                errorMessage={errors.promoCurrency?.message}
                radioOptions={currencyOptions}
                register={register}
              />
            </FormSection>
            <InputWithLabel
              label="Maximum Discount Amount"
              labelStyle="input-label"
              type="number"
              name="promoMaxAmount"
              inputClass="input-class"
              containerStyle="input-container-class"
              register={register}
              errorMessage={errors.promoMaxAmount?.message}
            />
            <InputWithLabel
              label="Product Minimum Fee (optional)"
              labelStyle="input-label"
              type="number"
              name="minAmount"
              inputClass="input-class"
              containerStyle="input-container-class"
              register={register}
              errorMessage={errors.minAmount?.message}
            />
            <FormSection>
              <InputWithLabel
                label="Expiry Date"
                labelStyle="input-label"
                type="date"
                name="promoExpiry"
                inputClass="input-class"
                containerStyle="input-container-class"
                register={register}
                errorMessage={errors.promoExpiry?.message}
              />
              <Divider />
              <RadioInput
                label="Promo Code Status"
                labelStyle="input-label"
                name="promoStatus"
                errorMessage={errors.promoStatus?.message}
                radioOptions={statusOptions}
                register={register}
              />
            </FormSection>
            <FormSection>
              <InputWithLabel
                label="Partner's Email"
                placeholder="name@example.com"
                labelStyle="input-label"
                type="text"
                name="email"
                inputClass="input-class"
                containerStyle="input-container-class"
                register={register}
                errorMessage={errors.email?.message}
              />
            </FormSection>
            <div style={{ marginBottom: "20px" }}>
              <TagInput
                label="Entity Codes (optional)"
                bottomText=""
                placeholder="Enter entity codes (seperated with a comma)"
                initialValues={entityCodes}
                containerClassName="entity-codes"
                getSelectedValues={(values) => {
                  setEntityCodes(values);
                  setValue("entityCode", values);
                }}
                maxTags={100}
                maxError="You cannot add more than 7 entites"
                minError="Entity code must be at least 3 characters"
                existError="You have added this already"
              />
            </div>
            <FormButton>
              {promoCode && (
                <CommonButton
                  text="Delete"
                  type="button"
                  style={deleteButtonStyle}
                  action={() => setDeleteConfirm(true)}
                />
              )}
              <CommonButton
                text={promoCode ? "Update" : "Create"}
                type="submit"
                loading={createState.isLoading || updateState.isLoading}
              />
            </FormButton>
          </PromoForm>
          <ConfirmDelete
            open={deleteConfirm}
            setOpen={setDeleteConfirm}
            toDelete="promo code"
            loading={deleteState.isLoading}
            handleDelete={handleDeletePromo}
          />
        </PromoDetailsLeft>

        <PromoDetailsRight>
          <PromoCodeUsers>
            <PromoUsersHeader>
              <p>{promoCode}</p>
              <Link to="/staff-dashboard/promo-codes">See all</Link>
            </PromoUsersHeader>
            <PromoUsersSubHeader>Users' Email Address</PromoUsersSubHeader>
            <PromoUsersBody>
              {!promoUsers?.length ? (
                <EmptyPromoUsers>
                  <EmptyContent emptyText="Promo code users' email will appear here" />
                </EmptyPromoUsers>
              ) : (
                promoUsers?.map((el, i) => (
                  <EachPromoUser key={i}>
                    <span>#</span> {el}
                  </EachPromoUser>
                ))
              )}
            </PromoUsersBody>
          </PromoCodeUsers>
        </PromoDetailsRight>
      </PromoDetailsBody>
    </PromoDetailsContainer>
  );
};

export default PromoDetails;
