import React from "react";

export const UserManage = ({ filled, hover }) => {
  return (
    <>
      {filled ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
          />
        </svg>
      ) : (
        <>
          {/* <svg 
                    width="20" 
                    height="20" 
                    viewBox="0 0 20 20" 
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.843 6.46868C11.843 8.67181 10.0401 10.4374 7.79046 10.4374C5.5416 10.4374 3.73794 8.67181 3.73794 6.46868C3.73794 4.26554 5.5416 2.5 7.79046 2.5C10.0401 2.5 11.843 4.26554 11.843 6.46868ZM1.66602 14.9311C1.66602 12.8917 4.48729 12.3814 7.79046 12.3814C11.1116 12.3814 13.9149 12.9093 13.9149 14.9503C13.9149 16.9897 11.0936 17.5 7.79046 17.5C4.46935 17.5 1.66602 16.9722 1.66602 14.9311ZM13.4772 6.54062C13.4772 7.66255 13.1331 8.70942 12.5297 9.57902C12.4669 9.66845 12.5224 9.78903 12.6316 9.80819C12.7833 9.83295 12.939 9.84812 13.098 9.85131C14.6799 9.89204 16.0995 8.89468 16.4917 7.39265C17.0731 5.16396 15.3673 3.16286 13.1943 3.16286C12.9586 3.16286 12.7327 3.18681 12.5126 3.23073C12.4824 3.23712 12.4498 3.25149 12.4335 3.27705C12.4123 3.30979 12.4278 3.35211 12.449 3.38006C13.1021 4.2768 13.4772 5.36839 13.4772 6.54062ZM16.0971 11.4186C17.1603 11.6222 17.8591 12.0366 18.1486 12.6411C18.394 13.1362 18.394 13.7112 18.1486 14.2062C17.7058 15.1421 16.2773 15.4432 15.722 15.5206C15.607 15.5366 15.5149 15.4408 15.5271 15.3274C15.8109 12.7338 13.5547 11.504 12.9708 11.2213C12.9464 11.2078 12.9407 11.1886 12.9431 11.1758C12.9447 11.1678 12.9553 11.1551 12.9741 11.1527C14.2371 11.1287 15.5956 11.2988 16.0971 11.4186Z" 
                    fill={hover ? "#00A2D4" : "#242627"}/>
                </svg> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-8 h-"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
            />
          </svg>
        </>
      )}
    </>
  );
};
