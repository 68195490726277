import { CheckoutController, CheckoutSection } from "containers";
import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Container } from "../styled";
import styled from "styled-components";
import { ReviewTab } from "utils/config";
import HeaderCheckout from "components/Header/HeaderCheckout";
import { useSelector } from "react-redux";
import { store } from "redux/Store";
import { setCheckoutProgress } from "redux/Slices";
import ReviewCard from "components/cards/ReviewCard";
import {
  useSubmitLaunchMutation,
  useViewMembersMutation,
  useViewShareholdersMutation,
} from "services/launchService";
import { useEffect } from "react";
import { Puff } from "react-loading-icons";
import { handleMembersCodeMerge } from "../action";
import { toast } from "react-hot-toast";

const ProprietorsReview = () => {
  const [mergedResponse, setMergedResponse] = useState([]);

  const { launchResponse } = useSelector((store) => store.LaunchReducer);
  const [viewShareholders, viewShareholderState] = useViewShareholdersMutation();
  const [viewMembers, viewMembersState] = useViewMembersMutation();
  const [submitLaunch] = useSubmitLaunchMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const handleNext = async () => {
    const requiredData = {
      launchCode: launchResponse.launchCode,
    };
    const response = await submitLaunch(requiredData);
    const error = response.error;
    if (response.data) {
      toast.success(response.data.registrationStatus);
      navigate("/launch/success");
    } else {
      toast.error(error.data.message);
    }
  };

  const handlePrev = () => {
    navigate("/launch/review/business-info");
  };

  const handleNavigate = () => {
    navigate("/launch/proprietors-info");
    localStorage.setItem("navigatedFrom", location.pathname);
  };

  const handleMerge = async () => {
    let memberInfo = await viewMembers(launchResponse);
    let membersUpdatedData = [...memberInfo.data.businessMembers];

    let shareholderInfo = await viewShareholders(launchResponse);
    let shareholdersUpdatedData = [...shareholderInfo.data.businessShareholders];

    let finalMerge = handleMembersCodeMerge(shareholdersUpdatedData, membersUpdatedData);

    setMergedResponse(finalMerge);
  };

  useEffect(() => {
    handleMerge();
  }, []);

  // Set the progress of the application
  useEffect(() => {
    store.dispatch(setCheckoutProgress({ total: 13, current: 13 })); // total- total pages and current - current page
  }, []);
  return (
    <Container>
      <HeaderCheckout />
      <Body>
        <CheckoutSection
          title={"Review Information"}
          HeaderParagraph="Please ensure all information provided for this business are correct"
        />
        <Nav>
          {ReviewTab.map((item, index) => (
            <ReviweTabWrapper to={item.path} key={index}>
              <NavLink to={item.path} style={({ isActive }) => (isActive ? ActiveStyles : {})}>
                {item.title}
              </NavLink>
            </ReviweTabWrapper>
          ))}
        </Nav>

        {viewShareholderState.isLoading ||
          (viewMembersState.isLoading && (
            <Loading height="50vh">
              <Puff stroke="#00A2D4" fill="white" />
            </Loading>
          ))}

        <CardWrapper>
          {mergedResponse.map((shareholder, index) => (
            <ReviewCard
              key={index}
              number={index + 1}
              name={shareholder?.memberName}
              shares={shareholder?.shareholderRegistrationNumber ? "company" : "individual"}
              email={shareholder?.memberEmail}
              phone={shareholder?.memberPhone}
              sharesPercentage={shareholder?.shareholderOwnershipPercentage}
              icon
              memberCode={shareholder?.memberCode}
              editAction={handleNavigate}
            />
          ))}
        </CardWrapper>
        <ButtonWrapper>
          <CheckoutController
            backText="Previous"
            forwardText="Done"
            forwardAction={handleNext}
            backAction={handlePrev}
          />
        </ButtonWrapper>
      </Body>
    </Container>
  );
};

export default ProprietorsReview;

const Nav = styled.nav`
  background: #ffffff;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #edf1f7;
  padding: 20px 40px 0px 40px;

  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
`;
const ReviweTabWrapper = styled.div`
  display: flex;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 7px 10px;
    transition: 0.3s all ease;
    padding-bottom: 20px;

    border: none;

    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #959697;
    white-space: nowrap;
  }
`;

const EditText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #00a2d4;
`;
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 40px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 40px;
`;
const Body = styled.form`
  display: flex;
  flex-flow: column;
  height: 100%;
  margin: auto;
  width: 100%;
  max-width: 962px;
  background-color: white;
  border: 1px solid #edf1f6;
  border-top: none;
  flex: 1;
  padding-bottom: 50px;
  border-top: none;
`;

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px;
  height: ${({ height }) => height && height};
`;

const ActiveStyles = {
  color: "#151717",
  borderBottom: "4px solid #00A2D4",
  borderRadius: 0,
};
