import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PersistAuth } from "utils/auth";

export const serviceApi = createApi({
  reducerPath: "serviceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_DEV_BASE_URL}`,
    prepareHeaders: async (headers, { getState }) => {
      // If we have a token set in state, let's assume that we should be passing it.
      const token = await PersistAuth(getState().UserDataReducer.userInfo);
      headers.set("Access-Control-Allow-Origin", "*");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Application"],

  endpoints: (builder) => ({
    // get all services
    getAllServices: builder.query({
      query: () => "/services/all",
    }),

    createSubscription: builder.mutation({
      query: (data) => ({
        url: "/create-subscription",
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),

    refreshToken: builder.mutation({
      query: (values) => ({
        url: "/user/refreshToken",
        method: "POST",
        body: values,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const { useGetAllServicesQuery, useCreateSubscriptionMutation, useRefreshTokenMutation } =
  serviceApi;
