export const theme = {
	grey1: "#242627",
	grey2: "#4E5152", // priority
	grey3: "#727474",
	grey4: "#959697",
	grey5: "#D5D5D5",
	grey6: "#E3E3E3",
	grey7: "#F1F1F1",
    darkgrey: "#ccf3ff7a",
	blue1: "#0082AA",
	blue2: "#00A2D4", // ✅ color + background
	blue3: "#00C3FF",
	blue4: "#2ACDFF",
	blue5: "#55D7FF",
	blue6: "#80E1FF",
	blue7: "#AAEBFF",
	blue8: "#CCF3FF",
    blue9: "#5BC3E3",
	blue10: "#00a2d4b7",
	red1: "#9E3427",
	red2: "#C54130",
	red3: "#ED4E3A",
	red4: "#F06B5B",
	red5: "#F3897C",
	red6: "#F6A69C",
	red7: "#F9C4BD",
	red8: "#F9C4BD",
	red9:"#BD1C1C",
	red10:"#ed4e3a19",
	red11:"#dd512e",
	orange:"#ffb5b5", // ✅
	border: "#EDF1F6",
	border1:"#ececec",
	statusGreen: "#00D448",
	backgroundStatusGreen:"#00d44819",
	statusPurple: "#D400CC",
	backgroundStatusPurple:"#d400cc19", // ✅
	statusYellow: "#FFBF29",
	backgroundStatusYellow:"#ffbf2919", // ✅
	backgroundStatusYellow2:"#ffbf290c", // ✅
	white: "#FFFFFF",
    white1: "#edf1f7",
    white2: "#151717",
	white3:"#fcfcfc",
	white4:"#fafafa",
	white5:"#e0feff",
	white6:"#cbd5e1",
	white7:"#f8fafc",
	lightorange:"#D7D7D7",
    lightwhite: "#ffffffa3",
	palin:"#62626262",
	black: "#000000",
    dark: "#9596970a",
	dark2: "#95969719",
	dark3: "#b9bec414",
	dark4:"#95969714",
	dark5:"#ffffff34",
	dark6:"#ffffff66",
    darkgreen:"#00d4480c",
	dullgreen:"#00a2d40c",
	dullgreen2: "#00a2d419",
	pale:"#d400cc0c",
	

};
