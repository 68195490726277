import React from "react";

export const ResourcesIcon = ({ filled, hover, color, width, height, size }) => {
  return (
    <>
      {filled ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7239_67104)">
            <path
              d="M12.75 10.5C12.75 10.6989 12.671 10.8897 12.5303 11.0303C12.3897 11.171 12.1989 11.25 12 11.25H6C5.80109 11.25 5.61032 11.171 5.46967 11.0303C5.32902 10.8897 5.25 10.6989 5.25 10.5C5.25 10.3011 5.32902 10.1103 5.46967 9.96969C5.61032 9.82903 5.80109 9.75002 6 9.75002H12C12.1989 9.75002 12.3897 9.82903 12.5303 9.96969C12.671 10.1103 12.75 10.3011 12.75 10.5ZM9.75 12.75H6C5.80109 12.75 5.61032 12.829 5.46967 12.9697C5.32902 13.1103 5.25 13.3011 5.25 13.5C5.25 13.6989 5.32902 13.8897 5.46967 14.0303C5.61032 14.171 5.80109 14.25 6 14.25H9.75C9.94891 14.25 10.1397 14.171 10.2803 14.0303C10.421 13.8897 10.5 13.6989 10.5 13.5C10.5 13.3011 10.421 13.1103 10.2803 12.9697C10.1397 12.829 9.94891 12.75 9.75 12.75ZM16.5 7.86377V14.25C16.4988 15.2442 16.1033 16.1973 15.4003 16.9004C14.6973 17.6034 13.7442 17.9988 12.75 18H5.25C4.2558 17.9988 3.30267 17.6034 2.59966 16.9004C1.89666 16.1973 1.50119 15.2442 1.5 14.25V3.75002C1.50119 2.75582 1.89666 1.80269 2.59966 1.09968C3.30267 0.396679 4.2558 0.00120818 5.25 1.72865e-05H8.63625C9.32596 -0.00175791 10.0092 0.133208 10.6464 0.397112C11.2836 0.661015 11.8622 1.04862 12.3488 1.53752L14.9618 4.15202C15.4509 4.6382 15.8388 5.21661 16.1028 5.85375C16.3669 6.49089 16.5019 7.17408 16.5 7.86377ZM11.2882 2.59802C11.0522 2.36939 10.7872 2.17271 10.5 2.01302V5.25002C10.5 5.44893 10.579 5.63969 10.7197 5.78035C10.8603 5.921 11.0511 6.00002 11.25 6.00002H14.487C14.3272 5.71291 14.1303 5.44813 13.9012 5.21252L11.2882 2.59802ZM15 7.86377C15 7.74002 14.976 7.62152 14.9647 7.50002H11.25C10.6533 7.50002 10.081 7.26296 9.65901 6.84101C9.23705 6.41905 9 5.84675 9 5.25002V1.53527C8.8785 1.52402 8.75925 1.50002 8.63625 1.50002H5.25C4.65326 1.50002 4.08097 1.73707 3.65901 2.15903C3.23705 2.58098 3 3.15328 3 3.75002V14.25C3 14.8468 3.23705 15.4191 3.65901 15.841C4.08097 16.263 4.65326 16.5 5.25 16.5H12.75C13.3467 16.5 13.919 16.263 14.341 15.841C14.7629 15.4191 15 14.8468 15 14.25V7.86377Z"
              fill="#00A2D4"
            />
          </g>
          <defs>
            <clipPath id="clip0_7239_67104">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7228_67464)">
            <path
              d="M12.75 10.5C12.75 10.6989 12.671 10.8897 12.5303 11.0303C12.3897 11.171 12.1989 11.25 12 11.25H6C5.80109 11.25 5.61032 11.171 5.46967 11.0303C5.32902 10.8897 5.25 10.6989 5.25 10.5C5.25 10.3011 5.32902 10.1103 5.46967 9.96969C5.61032 9.82903 5.80109 9.75002 6 9.75002H12C12.1989 9.75002 12.3897 9.82903 12.5303 9.96969C12.671 10.1103 12.75 10.3011 12.75 10.5ZM9.75 12.75H6C5.80109 12.75 5.61032 12.829 5.46967 12.9697C5.32902 13.1103 5.25 13.3011 5.25 13.5C5.25 13.6989 5.32902 13.8897 5.46967 14.0303C5.61032 14.171 5.80109 14.25 6 14.25H9.75C9.94891 14.25 10.1397 14.171 10.2803 14.0303C10.421 13.8897 10.5 13.6989 10.5 13.5C10.5 13.3011 10.421 13.1103 10.2803 12.9697C10.1397 12.829 9.94891 12.75 9.75 12.75ZM16.5 7.86377V14.25C16.4988 15.2442 16.1033 16.1973 15.4003 16.9004C14.6973 17.6034 13.7442 17.9988 12.75 18H5.25C4.2558 17.9988 3.30267 17.6034 2.59966 16.9004C1.89666 16.1973 1.50119 15.2442 1.5 14.25V3.75002C1.50119 2.75582 1.89666 1.80269 2.59966 1.09968C3.30267 0.396679 4.2558 0.00120818 5.25 1.72865e-05H8.63625C9.32596 -0.00175791 10.0092 0.133208 10.6464 0.397112C11.2836 0.661015 11.8622 1.04862 12.3488 1.53752L14.9618 4.15202C15.4509 4.6382 15.8388 5.21661 16.1028 5.85375C16.3669 6.49089 16.5019 7.17408 16.5 7.86377ZM11.2882 2.59802C11.0522 2.36939 10.7872 2.17271 10.5 2.01302V5.25002C10.5 5.44893 10.579 5.63969 10.7197 5.78035C10.8603 5.921 11.0511 6.00002 11.25 6.00002H14.487C14.3272 5.71291 14.1303 5.44813 13.9012 5.21252L11.2882 2.59802ZM15 7.86377C15 7.74002 14.976 7.62152 14.9647 7.50002H11.25C10.6533 7.50002 10.081 7.26296 9.65901 6.84101C9.23705 6.41905 9 5.84675 9 5.25002V1.53527C8.8785 1.52402 8.75925 1.50002 8.63625 1.50002H5.25C4.65326 1.50002 4.08097 1.73707 3.65901 2.15903C3.23705 2.58098 3 3.15328 3 3.75002V14.25C3 14.8468 3.23705 15.4191 3.65901 15.841C4.08097 16.263 4.65326 16.5 5.25 16.5H12.75C13.3467 16.5 13.919 16.263 14.341 15.841C14.7629 15.4191 15 14.8468 15 14.25V7.86377Z"
              fill={hover ? "#00A2D4" : "#242627"}
            />
          </g>
          <defs>
            <clipPath id="clip0_7228_67464">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
