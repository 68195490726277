import React from "react";

export const BankAccountIcon = ({ filled, hover }) => {
  return (
    <>
      {filled ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7239_67096)">
            <path
              d="M4.125 12.75C4.74632 12.75 5.25 12.2463 5.25 11.625C5.25 11.0037 4.74632 10.5 4.125 10.5C3.50368 10.5 3 11.0037 3 11.625C3 12.2463 3.50368 12.75 4.125 12.75Z"
              fill="#00A2D4"
            />
            <path
              d="M14.25 2.25H3.75C2.7558 2.25119 1.80267 2.64666 1.09966 3.34966C0.396661 4.05267 0.00119089 5.0058 0 6L0 12C0.00119089 12.9942 0.396661 13.9473 1.09966 14.6503C1.80267 15.3533 2.7558 15.7488 3.75 15.75H14.25C15.2442 15.7488 16.1973 15.3533 16.9003 14.6503C17.6033 13.9473 17.9988 12.9942 18 12V6C17.9988 5.0058 17.6033 4.05267 16.9003 3.34966C16.1973 2.64666 15.2442 2.25119 14.25 2.25ZM3.75 3.75H14.25C14.8467 3.75 15.419 3.98705 15.841 4.40901C16.2629 4.83097 16.5 5.40326 16.5 6H1.5C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75ZM14.25 14.25H3.75C3.15326 14.25 2.58097 14.0129 2.15901 13.591C1.73705 13.169 1.5 12.5967 1.5 12V7.5H16.5V12C16.5 12.5967 16.2629 13.169 15.841 13.591C15.419 14.0129 14.8467 14.25 14.25 14.25Z"
              fill="#00A2D4"
            />
          </g>
          <defs>
            <clipPath id="clip0_7239_67096">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7228_67476)">
            <path
              d="M4.125 12.75C4.74632 12.75 5.25 12.2463 5.25 11.625C5.25 11.0037 4.74632 10.5 4.125 10.5C3.50368 10.5 3 11.0037 3 11.625C3 12.2463 3.50368 12.75 4.125 12.75Z"
              fill={hover ? "#00A2D4" : "#242627"}
            />
            <path
              d="M14.25 2.25H3.75C2.7558 2.25119 1.80267 2.64666 1.09966 3.34966C0.396661 4.05267 0.00119089 5.0058 0 6L0 12C0.00119089 12.9942 0.396661 13.9473 1.09966 14.6503C1.80267 15.3533 2.7558 15.7488 3.75 15.75H14.25C15.2442 15.7488 16.1973 15.3533 16.9003 14.6503C17.6033 13.9473 17.9988 12.9942 18 12V6C17.9988 5.0058 17.6033 4.05267 16.9003 3.34966C16.1973 2.64666 15.2442 2.25119 14.25 2.25ZM3.75 3.75H14.25C14.8467 3.75 15.419 3.98705 15.841 4.40901C16.2629 4.83097 16.5 5.40326 16.5 6H1.5C1.5 5.40326 1.73705 4.83097 2.15901 4.40901C2.58097 3.98705 3.15326 3.75 3.75 3.75ZM14.25 14.25H3.75C3.15326 14.25 2.58097 14.0129 2.15901 13.591C1.73705 13.169 1.5 12.5967 1.5 12V7.5H16.5V12C16.5 12.5967 16.2629 13.169 15.841 13.591C15.419 14.0129 14.8467 14.25 14.25 14.25Z"
              fill={hover ? "#00A2D4" : "#242627"}
            />
          </g>
          <defs>
            <clipPath id="clip0_7228_67476">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
