import React from "react";

export const HiringIcon = ({ filled, hover }) => {
  return (
    <>
      {filled ? (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.9118 6.57571C14.9118 9.02324 12.9494 10.9858 10.5002 10.9858C8.05174 10.9858 6.08851 9.02324 6.08851 6.57571C6.08851 4.12819 8.05174 2.1665 10.5002 2.1665C12.9494 2.1665 14.9118 4.12819 14.9118 6.57571ZM10.5002 18.8332C6.88547 18.8332 3.8335 18.2457 3.8335 15.979C3.8335 13.7115 6.90465 13.1448 10.5002 13.1448C14.1157 13.1448 17.1668 13.7323 17.1668 15.999C17.1668 18.2665 14.0957 18.8332 10.5002 18.8332Z"
            fill="#00A2D4"
          />
        </svg>
      ) : (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.18164 12.5801H17.3816V18.7251H4.18164V12.5801Z"
            fill="white"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7825 13.8301C7.23164 13.8301 5.43164 14.4401 5.43164 15.6442C5.43164 16.8592 7.23164 17.4751 10.7825 17.4751C14.3325 17.4751 16.1316 16.8651 16.1316 15.6609C16.1316 14.4459 14.3325 13.8301 10.7825 13.8301ZM10.7825 18.7251C9.14997 18.7251 4.18164 18.7251 4.18164 15.6442C4.18164 12.8976 7.94914 12.5801 10.7825 12.5801C12.415 12.5801 17.3816 12.5801 17.3816 15.6609C17.3816 18.4076 13.615 18.7251 10.7825 18.7251Z"
            fill={hover ? "#00A2D4" : "#242627"}
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.35742 2.16675H15.2074V11.0156H6.35742V2.16675Z"
            fill="white"
          />

          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.7833 3.3565C8.99911 3.3565 7.54745 4.80734 7.54745 6.5915C7.54161 8.36984 8.98245 9.81984 10.7591 9.8265L10.7833 10.4215V9.8265C12.5666 9.8265 14.0174 8.37484 14.0174 6.5915C14.0174 4.80734 12.5666 3.3565 10.7833 3.3565ZM10.7833 11.0157H10.7566C8.32161 11.0082 6.34911 9.02234 6.35745 6.589C6.35745 4.1515 8.34245 2.1665 10.7833 2.1665C13.2233 2.1665 15.2074 4.1515 15.2074 6.5915C15.2074 9.0315 13.2233 11.0157 10.7833 11.0157Z"
            fill={hover ? "#00A2D4" : "#242627"}
          />
        </svg>
      )}
    </>
  );
};
