import React from "react";

export const IntellectualIcon = ({ filled, hover }) => {
  return (
    <>
      {filled ? (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.74977 9.04688H6.00977C6.35477 9.04688 6.63477 9.32688 6.63477 9.67188C6.63477 10.0169 6.35477 10.2969 6.00977 10.2969H5.74977C5.40477 10.2969 5.12477 10.0169 5.12477 9.67188C5.12477 9.32688 5.40477 9.04688 5.74977 9.04688"
            fill="#00A2D4"
          />
        </svg>
      ) : (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.332 17.5L2.1332 17.5L2.1332 1.52258L19.332 1.52258L19.332 17.5Z"
            // d="M10.6719 15.1344C10.3269 15.1344 10.0469 14.8544 10.0469 14.5094V6.05688C10.0469 5.71188 10.3269 5.43188 10.6719 5.43188C11.0169 5.43188 11.2969 5.71188 11.2969 6.05688V14.5094C11.2969 14.8544 11.0169 15.1344 10.6719 15.1344Z"
            fill={hover ? "#00A2D4" : "#242627"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.3337 16.25C16.4004 16.25 18.082 14.5683 18.082 12.5017L18.082 6.52083C18.082 4.45417 16.4004 2.7725 14.3337 2.7725H7.13037C5.0637 2.7725 3.38287 4.45417 3.38287 6.52083V12.5017C3.38287 14.5683 5.0637 16.25 7.13037 16.25L14.3337 16.25ZM7.13037 1.5225H14.3337C17.0895 1.5225 19.332 3.765 19.332 6.52083L19.332 12.5017C19.332 15.2583 17.0895 17.5 14.3337 17.5L7.13037 17.5C4.37453 17.5 2.13287 15.2583 2.13287 12.5017L2.13287 6.52083C2.13287 3.765 4.37453 1.5225 7.13037 1.5225V1.5225Z"
            // d="M14.4961 15.1345C14.1511 15.1345 13.8711 14.8545 13.8711 14.5095V11.8145C13.8711 11.4695 14.1511 11.1895 14.4961 11.1895C14.8411 11.1895 15.1211 11.4695 15.1211 11.8145V14.5095C15.1211 14.8545 14.8411 15.1345 14.4961 15.1345Z"
            fill={hover ? "#00A2D4" : "#242627"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4286 12.4688L14.9277 12.4688C15.2727 12.4688 15.5527 12.7488 15.5527 13.0938C15.5527 13.4388 15.2727 13.7188 14.9277 13.7188L10.4286 13.7188C10.0836 13.7188 9.80357 13.4388 9.80357 13.0938C9.80357 12.7488 10.0836 12.4688 10.4286 12.4688"
            // d="M6.73497 2.58325C4.42497 2.58325 2.93164 4.16409 2.93164 6.61242V13.9708C2.93164 16.4191 4.42497 17.9999 6.73497 17.9999H14.545C16.8558 17.9999 18.3483 16.4191 18.3483 13.9708V6.61242C18.3483 4.16409 16.8558 2.58325 14.545 2.58325H6.73497ZM14.545 19.2499H6.73497C3.71247 19.2499 1.68164 17.1283 1.68164 13.9708V6.61242C1.68164 3.45492 3.71247 1.33325 6.73497 1.33325H14.545C17.5675 1.33325 19.5983 3.45492 19.5983 6.61242V13.9708C19.5983 17.1283 17.5675 19.2499 14.545 19.2499Z"
            fill={hover ? "#00A2D4" : "#242627"}
          />
        </svg>
      )}
    </>
  );
};

