import React from "react";

export const ManageIcon = ({ filled, hover }) => {
  return (
    <>
      {filled ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7239_67045)">
            <path
              d="M16.8634 8.23188L8.99963 12.9509L1.13588 8.23188C0.965316 8.12954 0.76108 8.09915 0.568105 8.1474C0.37513 8.19564 0.209224 8.31857 0.106883 8.48913C0.00454304 8.6597 -0.0258482 8.86394 0.0223956 9.05691C0.0706393 9.24989 0.193566 9.41579 0.364133 9.51813L8.61413 14.4681C8.73078 14.5382 8.8643 14.5752 9.00039 14.5752C9.13647 14.5752 9.26999 14.5382 9.38663 14.4681L17.6366 9.51813C17.8072 9.41579 17.9301 9.24989 17.9784 9.05691C18.0266 8.86394 17.9962 8.6597 17.8939 8.48913C17.7915 8.31857 17.6256 8.19564 17.4327 8.1474C17.2397 8.09915 17.0355 8.12954 16.8649 8.23188H16.8634Z"
              fill="#00A2D4"
            />
            <path
              d="M16.8634 11.6577L8.99963 16.3759L1.13588 11.6577C1.05142 11.607 0.957815 11.5734 0.860395 11.559C0.762975 11.5445 0.663655 11.5493 0.568103 11.5732C0.472552 11.5971 0.382641 11.6395 0.303504 11.6982C0.224368 11.7568 0.157555 11.8305 0.106881 11.9149C0.0562074 11.9994 0.0226647 12.093 0.00816821 12.1904C-0.00632824 12.2878 -0.00149454 12.3871 0.0223933 12.4827C0.0706371 12.6757 0.193564 12.8416 0.364131 12.9439L8.61413 17.8939C8.73078 17.964 8.8643 18.001 9.00038 18.001C9.13647 18.001 9.26998 17.964 9.38663 17.8939L17.6366 12.9439C17.8072 12.8416 17.9301 12.6757 17.9784 12.4827C18.0266 12.2897 17.9962 12.0855 17.8939 11.9149C17.7915 11.7443 17.6256 11.6214 17.4327 11.5732C17.2397 11.5249 17.0354 11.5553 16.8649 11.6577H16.8634Z"
              fill="#00A2D4"
            />
            <path
              d="M8.99976 11.081C8.5952 11.0808 8.1983 10.9706 7.85151 10.7623L0.363513 6.26901C0.252623 6.20233 0.160871 6.1081 0.0971733 5.99547C0.0334757 5.88285 0 5.75566 0 5.62626C0 5.49687 0.0334757 5.36968 0.0971733 5.25705C0.160871 5.14442 0.252623 5.05019 0.363513 4.98351L7.85151 0.490262C8.19829 0.281934 8.59522 0.171875 8.99976 0.171875C9.40431 0.171875 9.80123 0.281934 10.148 0.490262L17.636 4.98351C17.7469 5.05019 17.8387 5.14442 17.9024 5.25705C17.966 5.36968 17.9995 5.49687 17.9995 5.62626C17.9995 5.75566 17.966 5.88285 17.9024 5.99547C17.8387 6.1081 17.7469 6.20233 17.636 6.26901L10.148 10.7623C9.80122 10.9706 9.40433 11.0808 8.99976 11.081ZM2.20776 5.62626L8.62476 9.47601C8.7381 9.54381 8.8677 9.57961 8.99976 9.57961C9.13183 9.57961 9.26143 9.54381 9.37476 9.47601L15.7918 5.62626L9.37476 1.77651C9.26143 1.70872 9.13183 1.67291 8.99976 1.67291C8.8677 1.67291 8.7381 1.70872 8.62476 1.77651L2.20776 5.62626Z"
              fill="#00A2D4"
            />
          </g>
          <defs>
            <clipPath id="clip0_7239_67045">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7228_67000)">
            <path
              d="M16.8634 8.23188L8.99963 12.9509L1.13588 8.23188C0.965316 8.12954 0.76108 8.09915 0.568105 8.1474C0.37513 8.19564 0.209224 8.31857 0.106883 8.48913C0.00454304 8.6597 -0.0258482 8.86394 0.0223956 9.05691C0.0706393 9.24989 0.193566 9.41579 0.364133 9.51813L8.61413 14.4681C8.73078 14.5382 8.8643 14.5752 9.00039 14.5752C9.13647 14.5752 9.26999 14.5382 9.38663 14.4681L17.6366 9.51813C17.8072 9.41579 17.9301 9.24989 17.9784 9.05691C18.0266 8.86394 17.9962 8.6597 17.8939 8.48913C17.7915 8.31857 17.6256 8.19564 17.4327 8.1474C17.2397 8.09915 17.0355 8.12954 16.8649 8.23188H16.8634Z"
              fill={hover ? "#00A2D4" : "#242627"}
            />
            <path
              d="M16.8634 11.6577L8.99963 16.3759L1.13588 11.6577C1.05142 11.607 0.957815 11.5734 0.860395 11.559C0.762975 11.5445 0.663655 11.5493 0.568103 11.5732C0.472552 11.5971 0.382641 11.6395 0.303504 11.6982C0.224368 11.7568 0.157555 11.8305 0.106881 11.9149C0.0562074 11.9994 0.0226647 12.093 0.00816821 12.1904C-0.00632824 12.2878 -0.00149454 12.3871 0.0223933 12.4827C0.0706371 12.6757 0.193564 12.8416 0.364131 12.9439L8.61413 17.8939C8.73078 17.964 8.8643 18.001 9.00038 18.001C9.13647 18.001 9.26998 17.964 9.38663 17.8939L17.6366 12.9439C17.8072 12.8416 17.9301 12.6757 17.9784 12.4827C18.0266 12.2897 17.9962 12.0855 17.8939 11.9149C17.7915 11.7443 17.6256 11.6214 17.4327 11.5732C17.2397 11.5249 17.0354 11.5553 16.8649 11.6577H16.8634Z"
              fill={hover ? "#00A2D4" : "#242627"}
            />
            <path
              d="M8.99976 11.081C8.5952 11.0808 8.1983 10.9706 7.85151 10.7623L0.363513 6.26901C0.252623 6.20233 0.160871 6.1081 0.0971733 5.99547C0.0334757 5.88285 0 5.75566 0 5.62626C0 5.49687 0.0334757 5.36968 0.0971733 5.25705C0.160871 5.14442 0.252623 5.05019 0.363513 4.98351L7.85151 0.490262C8.19829 0.281934 8.59522 0.171875 8.99976 0.171875C9.40431 0.171875 9.80123 0.281934 10.148 0.490262L17.636 4.98351C17.7469 5.05019 17.8387 5.14442 17.9024 5.25705C17.966 5.36968 17.9995 5.49687 17.9995 5.62626C17.9995 5.75566 17.966 5.88285 17.9024 5.99547C17.8387 6.1081 17.7469 6.20233 17.636 6.26901L10.148 10.7623C9.80122 10.9706 9.40433 11.0808 8.99976 11.081ZM2.20776 5.62626L8.62476 9.47601C8.7381 9.54381 8.8677 9.57961 8.99976 9.57961C9.13183 9.57961 9.26143 9.54381 9.37476 9.47601L15.7918 5.62626L9.37476 1.77651C9.26143 1.70872 9.13183 1.67291 8.99976 1.67291C8.8677 1.67291 8.7381 1.70872 8.62476 1.77651L2.20776 5.62626Z"
              fill={hover ? "#00A2D4" : "#242627"}
            />
          </g>
          <defs>
            <clipPath id="clip0_7228_67000">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
