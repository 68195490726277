import pdf from "../../../asset/images/pdf.png";
import png from "../../../asset/images/png.png";
import jpg from "../../../asset/images/jpg.png";
import docx from "../../../asset/images/docx-file-icon.png";

export const imageTypeImage = {
  pdf: pdf,
  jpg: jpg,
  png: png,
  docx: docx,
};
