import PaymentDetailsCard from "components/cards/PaymentCard";
import { format } from "date-fns";
import React from "react";
import { useOutletContext } from "react-router-dom";
import { getCurrencyInfo } from "utils/globalFunctions";
import { DetailContainer } from "./styles";

const ProductPayment = () => {
  const { viewComply } = useOutletContext();
  const { data, isLoading } = viewComply;

  let paymentDetails = data?.complyPayment[0];

  let amount =
    (getCurrencyInfo(paymentDetails?.paymentCurrency)?.symbol || "--") +
    paymentDetails?.paymentAmount;

  let status = paymentDetails?.paymentStatus;

  let statusStyle = {
    backgroundColor: status === "successful" ? "#73d895" : "#F9C4BD",
    borderRadius: "10px",
    padding: "5px 10px",
  };

  let info = [
    {
      fieldName: "Amount",
      fieldValue: amount,
    },
    {
      fieldName: "Currency",
      fieldValue: paymentDetails?.paymentCurrency?.toUpperCase() || "--",
    },
    {
      fieldName: "Transaction Id",
      fieldValue: paymentDetails?.paymentTransactionId,
    },
    {
      fieldName: "Provider",
      fieldValue: paymentDetails?.paymentProvider,
    },
    {
      fieldName: "Status",
      fieldValue: <span style={statusStyle}>{status}</span>,
    },
  ];
  console.log(paymentDetails?.paymentStatus);

  return (
    <DetailContainer>
      <PaymentDetailsCard
        info={info}
        date={data?.createdAt ? format(new Date(data?.createdAt), "dd MMMM, yyyy") : ""}
        isLoading={isLoading}
      />
    </DetailContainer>
  );
};

export default ProductPayment;
