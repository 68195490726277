import React from "react";

export const ProductIcon = ({ filled, hover }) => {
  return (
    <>
      {filled ? (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7239_67026)">
            <path
              d="M6.75 10.5H11.25C11.4489 10.5 11.6397 10.421 11.7803 10.2803C11.921 10.1397 12 9.94892 12 9.75C12 9.55109 11.921 9.36032 11.7803 9.21967C11.6397 9.07902 11.4489 9 11.25 9H6.75C6.55109 9 6.36032 9.07902 6.21967 9.21967C6.07902 9.36032 6 9.55109 6 9.75C6 9.94892 6.07902 10.1397 6.21967 10.2803C6.36032 10.421 6.55109 10.5 6.75 10.5V10.5Z"
              fill="#00A2D4"
            />
            <path
              d="M14.25 0H3.75C2.7558 0.00119089 1.80267 0.396661 1.09966 1.09966C0.396661 1.80267 0.00119089 2.7558 0 3.75L0 4.5C0.000218106 4.81631 0.0671256 5.12901 0.196357 5.41772C0.325588 5.70642 0.514239 5.96463 0.75 6.1755V14.25C0.751191 15.2442 1.14666 16.1973 1.84966 16.9003C2.55267 17.6033 3.5058 17.9988 4.5 18H13.5C14.4942 17.9988 15.4473 17.6033 16.1503 16.9003C16.8533 16.1973 17.2488 15.2442 17.25 14.25V6.1755C17.4858 5.96463 17.6744 5.70642 17.8036 5.41772C17.9329 5.12901 17.9998 4.81631 18 4.5V3.75C17.9988 2.7558 17.6033 1.80267 16.9003 1.09966C16.1973 0.396661 15.2442 0.00119089 14.25 0V0ZM1.5 3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V4.5C16.5 4.69891 16.421 4.88968 16.2803 5.03033C16.1397 5.17098 15.9489 5.25 15.75 5.25H2.25C2.05109 5.25 1.86032 5.17098 1.71967 5.03033C1.57902 4.88968 1.5 4.69891 1.5 4.5V3.75ZM15.75 14.25C15.75 14.8467 15.5129 15.419 15.091 15.841C14.669 16.2629 14.0967 16.5 13.5 16.5H4.5C3.90326 16.5 3.33097 16.2629 2.90901 15.841C2.48705 15.419 2.25 14.8467 2.25 14.25V6.75H15.75V14.25Z"
              fill="#00A2D4"
            />
          </g>
          <defs>
            <clipPath id="clip0_7239_67026">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_7228_67526)">
            <path
              d="M6.75 10.5H11.25C11.4489 10.5 11.6397 10.421 11.7803 10.2803C11.921 10.1397 12 9.94892 12 9.75C12 9.55109 11.921 9.36032 11.7803 9.21967C11.6397 9.07902 11.4489 9 11.25 9H6.75C6.55109 9 6.36032 9.07902 6.21967 9.21967C6.07902 9.36032 6 9.55109 6 9.75C6 9.94892 6.07902 10.1397 6.21967 10.2803C6.36032 10.421 6.55109 10.5 6.75 10.5V10.5Z"
              fill={hover ? "#00A2D4" : "#242627"}
            />
            <path
              d="M14.25 0H3.75C2.7558 0.00119089 1.80267 0.396661 1.09966 1.09966C0.396661 1.80267 0.00119089 2.7558 0 3.75L0 4.5C0.000218106 4.81631 0.0671256 5.12901 0.196357 5.41772C0.325588 5.70642 0.514239 5.96463 0.75 6.1755V14.25C0.751191 15.2442 1.14666 16.1973 1.84966 16.9003C2.55267 17.6033 3.5058 17.9988 4.5 18H13.5C14.4942 17.9988 15.4473 17.6033 16.1503 16.9003C16.8533 16.1973 17.2488 15.2442 17.25 14.25V6.1755C17.4858 5.96463 17.6744 5.70642 17.8036 5.41772C17.9329 5.12901 17.9998 4.81631 18 4.5V3.75C17.9988 2.7558 17.6033 1.80267 16.9003 1.09966C16.1973 0.396661 15.2442 0.00119089 14.25 0V0ZM1.5 3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H14.25C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V4.5C16.5 4.69891 16.421 4.88968 16.2803 5.03033C16.1397 5.17098 15.9489 5.25 15.75 5.25H2.25C2.05109 5.25 1.86032 5.17098 1.71967 5.03033C1.57902 4.88968 1.5 4.69891 1.5 4.5V3.75ZM15.75 14.25C15.75 14.8467 15.5129 15.419 15.091 15.841C14.669 16.2629 14.0967 16.5 13.5 16.5H4.5C3.90326 16.5 3.33097 16.2629 2.90901 15.841C2.48705 15.419 2.25 14.8467 2.25 14.25V6.75H15.75V14.25Z"
              fill={hover ? "#00A2D4" : "#242627"}
            />
          </g>
          <defs>
            <clipPath id="clip0_7228_67526">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};
