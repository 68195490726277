import React from "react";

export const CountryIcon = ({ filled, hover }) => {
  return (
    <>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8809 5.33057C15.1973 5.33057 15.4588 5.56569 15.5002 5.87076L15.5059 5.95557V17.3037C15.5059 17.6489 15.226 17.9287 14.8809 17.9287C14.5644 17.9287 14.303 17.6936 14.2616 17.3885L14.2559 17.3037V5.95557C14.2559 5.61039 14.5357 5.33057 14.8809 5.33057Z"
          fill={filled || hover ? "#00A2D4" : "#242627"}
        />
        <path
          d="M17.8358 13.4492C18.0793 13.2046 18.475 13.2037 18.7196 13.4472C18.942 13.6686 18.963 14.0157 18.782 14.2608L18.7217 14.3311L15.3235 17.7449C15.1014 17.9681 14.7529 17.9884 14.5078 17.8058L14.4376 17.7449L11.0395 14.3311C10.7959 14.0864 10.7969 13.6907 11.0415 13.4472C11.2639 13.2258 11.6112 13.2064 11.8554 13.3885L11.9254 13.4492L14.8802 16.4175L17.8358 13.4492Z"
          fill={filled || hover ? "#00A2D4" : "#242627"}
        />
        <path
          d="M6.60742 3.06934C6.92383 3.06934 7.18533 3.30446 7.22672 3.60953L7.23242 3.69434V15.0425C7.23242 15.3877 6.9526 15.6675 6.60742 15.6675C6.29101 15.6675 6.02951 15.4324 5.98813 15.1273L5.98242 15.0425V3.69434C5.98242 3.34916 6.26224 3.06934 6.60742 3.06934Z"
          fill={filled || hover ? "#00A2D4" : "#242627"}
        />
        <path
          d="M6.16417 3.25293C6.38627 3.0298 6.73481 3.00952 6.9799 3.19208L7.05009 3.25293L10.4482 6.66682C10.6918 6.91146 10.6908 7.30719 10.4462 7.5507C10.2238 7.77207 9.87653 7.79144 9.63228 7.60933L9.56232 7.54866L6.60695 4.5794L3.65195 7.54866C3.43057 7.77106 3.0834 7.79203 2.8383 7.61105L2.76807 7.5507C2.54566 7.32932 2.52469 6.98215 2.70567 6.73706L2.76602 6.66682L6.16417 3.25293Z"
          fill={filled || hover ? "#00A2D4" : "#242627"}
        />
      </svg>
    </>
  );
};
