import React from "react";

export const EntityIcon = ({ filled, hover }) => {
  return (
    <>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1896 9.84326L9.31708 12.6324L12.1054 11.7591L12.9787 8.96992L10.1896 9.84326ZM8.36374 14.2099C8.20041 14.2099 8.04041 14.1458 7.92124 14.0274C7.75708 13.8624 7.69708 13.6199 7.76708 13.3991L9.09458 9.15826C9.15541 8.96159 9.30874 8.80909 9.50374 8.74826L13.7446 7.42076C13.9671 7.34992 14.2087 7.41076 14.3737 7.57492C14.5379 7.73992 14.5979 7.98242 14.5279 8.20326L13.2012 12.4441C13.1404 12.6399 12.9862 12.7933 12.7912 12.8541L8.55041 14.1816C8.48874 14.2008 8.42541 14.2099 8.36374 14.2099Z"
          fill={filled || hover ? "#00A2D4" : "#242627"}
        />
        <mask
          id="mask0_4320_33269"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="18"
          height="18"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.51367 2.1665H19.7818V19.4346H2.51367V2.1665Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_4320_33269)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1478 3.4165C7.07617 3.4165 3.76367 6.72984 3.76367 10.8007C3.76367 14.8723 7.07617 18.1848 11.1478 18.1848C15.2195 18.1848 18.532 14.8723 18.532 10.8007C18.532 6.72984 15.2195 3.4165 11.1478 3.4165ZM11.1478 19.4348C6.38701 19.4348 2.51367 15.5615 2.51367 10.8007C2.51367 6.03984 6.38701 2.1665 11.1478 2.1665C15.9087 2.1665 19.782 6.03984 19.782 10.8007C19.782 15.5615 15.9087 19.4348 11.1478 19.4348Z"
            fill={filled || hover ? "#00A2D4" : "#242627"}
          />
        </g>
      </svg>
    </>
  );
};
