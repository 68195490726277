import React from "react";

const DeleteIcon = ({ color, onClick, onMouseDown }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      onMouseDown={onMouseDown}
      style={{ cursor: "pointer", minWidth: "20px" }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66749 14.8248C7.76416 14.8248 6.88349 14.8148 6.01216 14.7968C4.89749 14.7748 4.12616 14.0521 4.00016 12.9108C3.79016 11.0175 3.43082 6.55481 3.42749 6.51014C3.40482 6.23481 3.61016 5.99347 3.88549 5.97147C4.15682 5.96414 4.40216 6.15481 4.42416 6.42947C4.42749 6.47481 4.78616 10.9221 4.99416 12.8008C5.06549 13.4495 5.41549 13.7841 6.03282 13.7968C7.69949 13.8321 9.40016 13.8341 11.2335 13.8008C11.8895 13.7881 12.2442 13.4601 12.3175 12.7961C12.5242 10.9335 12.8842 6.47481 12.8882 6.42947C12.9102 6.15481 13.1535 5.96281 13.4262 5.97147C13.7015 5.99414 13.9068 6.23481 13.8848 6.51014C13.8808 6.55547 13.5195 11.0295 13.3115 12.9061C13.1822 14.0708 12.4128 14.7795 11.2515 14.8008C10.3628 14.8161 9.50549 14.8248 8.66749 14.8248Z"
        fill={color || "#4E5152"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3092 4.81787H3.00391C2.72791 4.81787 2.50391 4.59387 2.50391 4.31787C2.50391 4.04187 2.72791 3.81787 3.00391 3.81787H14.3092C14.5852 3.81787 14.8092 4.04187 14.8092 4.31787C14.8092 4.59387 14.5852 4.81787 14.3092 4.81787Z"
        fill={color || "#4E5152"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1303 4.8177C11.3716 4.8177 10.713 4.27703 10.5636 3.53303L10.4016 2.72237C10.3676 2.59903 10.227 2.4917 10.067 2.4917H7.24497C7.08497 2.4917 6.94431 2.59903 6.90364 2.75303L6.74831 3.53303C6.59964 4.27703 5.94031 4.8177 5.18164 4.8177C4.90564 4.8177 4.68164 4.5937 4.68164 4.3177C4.68164 4.0417 4.90564 3.8177 5.18164 3.8177C5.46564 3.8177 5.71231 3.61503 5.76831 3.33637L5.93031 2.5257C6.09497 1.90437 6.63297 1.4917 7.24497 1.4917H10.067C10.679 1.4917 11.217 1.90437 11.375 2.4957L11.5443 3.33637C11.5996 3.61503 11.8463 3.8177 12.1303 3.8177C12.4063 3.8177 12.6303 4.0417 12.6303 4.3177C12.6303 4.5937 12.4063 4.8177 12.1303 4.8177Z"
        fill={color || "#4E5152"}
      />
    </svg>
  );
};

export default DeleteIcon;
