import axios from "axios";
import { handleLogout } from "./globalFunctions";

export const PersistAuth = async (userInfo) => {
  const refreshToken = userInfo.refreshToken;

  // if (!userInfo?.tokenExpiresIn) {
  //   handleLogout();
  //   return;
  // }

  if (Date.now() > userInfo.tokenExpiresIn) {
    try {
      // token has expired
      // refresh the token
      const response = await axios.post(
        "/user/refreshToken",
        {
          refreshToken: refreshToken,
        },
        {
          baseURL: process.env.REACT_APP_DEV_BASE_URL,
        }
      );
      // store new token in local storage

      const newUserInfo = {
        ...userInfo,
        token: response.data.data.accessToken,
        refreshToken: response.data.data.newRefreshToken,
        tokenExpiresIn: response.data.data.tokenExpiresIn,
      };

      localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
      // return new token
      return newUserInfo.token;
    } catch (e) {
      // refresh token failed
      // console.log(e);

      // logout user
      handleLogout();
    }
  } else {
    // console.log("token has not expired");
  }
  return userInfo.token;
};
